import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../../assets/img/logo.png";
import "./style.css";

const Header = () => {
  const [isActive, setActive] = useState();

  window.onscroll = function () {
    windowScroll();
  };

  function windowScroll() {
    if (document.documentElement.scrollTop > 8) {
      document.getElementById("main-nav").className = "fixed";
    } else {
      document.getElementById("main-nav").className = "";
    }
  }

  const showMenu = () => {
    setActive(!isActive);
  };

  return (
    <header id="main-nav">
      <Link to="/">
        <img src={logo} alt="Israel Profético" className="logo" />
      </Link>

      <nav>
        <ul className="menu">
          <li className="menu-item">
            <Link to="caravana" spy={true} smooth={true} offset={-50}>
              Liderança
            </Link>
          </li>
          <li className="menu-item">
            <Link to="lideranca" spy={true} smooth={true} offset={-30}>
              A caravana
            </Link>
          </li>
          <li className="menu-item">
            <Link to="photos" spy={true} smooth={true} offset={-30}>
              Fotos
            </Link>
          </li>
          <li className="menu-item">
            <Link to="investiment" spy={true} smooth={true} offset={-60}>
              Investimento
            </Link>
          </li>
          <li className="menu-item">
            <Link to="roteiro" spy={true} smooth={true} offset={-50}>
              Roteiro
            </Link>
          </li>
          <li className="menu-item">
            <Link to="contact" spy={true} smooth={true}>
              Contato
            </Link>
          </li>
          <li className="menu-item">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSd3R7LUP7FzkqYyfFtS_16HSAg3DFqqmjtyaJNnttRDhfAQfg/viewform"
              target="__blank"
            >
              pré-inscrição
            </a>
          </li>
        </ul>

        <button onClick={showMenu}>
          <i>
            <FontAwesomeIcon icon={faBars} />
          </i>
        </button>

        <ul className={isActive ? "cel_navigator active" : "cel_navigator"}>
          <li className="menu-item">
            <Link
              to="caravana"
              spy={true}
              smooth={true}
              offset={-50}
              onClick={showMenu}
            >
              Liderança
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="lideranca"
              spy={true}
              smooth={true}
              offset={-30}
              onClick={showMenu}
            >
              A caravana
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="photos"
              spy={true}
              smooth={true}
              offset={-30}
              onClick={showMenu}
            >
              Fotos
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="investiment"
              spy={true}
              smooth={true}
              offset={-60}
              onClick={showMenu}
            >
              Investimento
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="roteiro"
              spy={true}
              smooth={true}
              offset={-50}
              onClick={showMenu}
            >
              Roteiro
            </Link>
          </li>
          <li className="menu-item">
            <Link to="contact" spy={true} smooth={true} onClick={showMenu}>
              Contato
            </Link>
          </li>
          <li className="menu-item">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSd3R7LUP7FzkqYyfFtS_16HSAg3DFqqmjtyaJNnttRDhfAQfg/viewform"
              target="__blank"
            >
              pré-inscrição
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
