import React from "react";
import "./style.css";

function Footer() {
  return (
    <footer>
      <div className="copyright">
        <p>All copyrights reserved © 2021 - COMUNICAÇÃO ATOS DE JUSTIÇA</p>
      </div>
    </footer>
  );
}

export default Footer;
