import React from "react";
import "./style.css";
import Header from "../Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStarOfDavid,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import Main from "../Main";
import Footer from "../Footer";
import video from "../../assets/videos/banner.webm";

function Home() {
  return (
    <>
      <Header />
      <div className="top" id="top">
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          controls={false}
          className="video_banner"
        >
          <source src={video} />
        </video>
        <div className="banner">
          <FontAwesomeIcon icon={faStarOfDavid} />
          <h1>CARAVANA ISRAEL - 2021</h1>
          <h2>Israel Profético, Ontem, Hoje e Amanhã</h2>
          <p>
            13 de Novembro de 2021 <FontAwesomeIcon icon={faCalendarAlt} />
          </p>
        </div>
      </div>
      <Main />
      <Footer />
    </>
  );
}

export default Home;
