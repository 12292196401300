import React from "react";
import "./style.css";

const PricingTable = () => {
  return (
    <div className="pricing-table">
      <div className="pricing-details">
        <h2>TERRESTRE</h2>
        <span>
          TOTAL USD $2.750
          <span>
            <ul>
              <li>
                <strong>
                  NO BOLETO OU CHEQUE ATÉ A VIAGEM (NEGOCIADO & PARCELADO PELA
                  EXCELLER TOUR)
                </strong>
              </li>
              <li>
                Hospedagem em Israel nos hoteis descritos ou similares <br /> 1
                - TEL AVIV: HOTEL BY14 TLV <br /> 2 - TIBERIAS: HOTEL LEONARDO
                TIBERIAS <br /> 3 - MAR MORTO: HOTEL DAVID DEAD SEA <br /> 4-
                JERUSALÉM: PRIMA PARK JERUSALÉM
              </li>
              <li>Café da manhã e jantar</li>
              <li>Traslados de chegada e saída</li>
              <li>Tours Conforme Roteiro</li>
              <li>Guia local em Português</li>
              <li>Tickets para visitas aos locais</li>
              <li>Audio guia & Motorista</li>
              <li>Gorjetas aos guias e motoristas</li>
              <li>Bonés e mapas de Israel</li>
              <li>
                <strong>
                  PS. PASSAGENS ATÉ SÃO PAULO, TAXAS AEROPORTUÁRIAS, ALMOÇOS,{" "}
                  <br />
                  BEBIDAS DURANTE AS REFEIÇÕES OU OUTROS NÃO DESCRITOS NÃO ESTÃO
                  INCLUSOS
                </strong>
              </li>
            </ul>
          </span>
          <h2>AÉREO</h2>
          <img src="http://israelprofetico.com.br/img/swiss.png" alt="" />
          <span>(VALOR APROXIMADO) USD 820 + TAXAS</span>
          <ul>
            <li>
              <strong>
                PARCELAMENTO NO CARTÃO DE CRÉDITO PELA EXCELLER JUNTO A
                OPERADORA
              </strong>
            </li>
            <li>INÍCIO DO PAGAMENTO 45 DIAS ANTES DA VIAGEM</li>
            <li>VÔO DE IDA - Guarulhos - Zurique (City tour) - Tel Aviv</li>
            <li>VÔO DE RETORNO - Tel Aviv - Zurique - Guarulhos</li>
          </ul>
        </span>
      </div>
    </div>
  );
};

export default PricingTable;
