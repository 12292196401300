import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import fotosalo from "../../assets/img/fotosalo3.png";
import dinho from "../../assets/img/dinho.png";
import img1 from "../../assets/img/israel/img1.jpg";
import img2 from "../../assets/img/israel/img2.jpg";
import img3 from "../../assets/img/israel/img3.jpg";
import img4 from "../../assets/img/israel/img4.jpg";
import img5 from "../../assets/img/israel/img5.jpg";
import img6 from "../../assets/img/israel/img6.jpg";
import img7 from "../../assets/img/israel/img7.jpg";
import img8 from "../../assets/img/israel/img8.jpg";
import img9 from "../../assets/img/israel/img9.jpg";
import img10 from "../../assets/img/israel/img10.JPG";
import img11 from "../../assets/img/israel/img11.JPG";
import img12 from "../../assets/img/israel/img12.JPG";
import img13 from "../../assets/img/israel/img13.JPG";
import img14 from "../../assets/img/israel/img14.JPG";
import img15 from "../../assets/img/israel/img15.JPG";
import img16 from "../../assets/img/israel/img16.JPG";
import img17 from "../../assets/img/israel/img17.JPG";
import img18 from "../../assets/img/israel/img18.JPG";
import exceller from "../../assets/img/exceller-tour.png";
import PricingTable from "../PricingTable";
import "./style.css";

const Main = () => {
  const [showScroll, setShowScroll] = useState();

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  var settings = {
    arrows: false,
    dots: true,
    lazyLoad: "progressive",
    infinite: true,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 6000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          autoplaySpeed: 4000,
        },
      },
    ],
  };

  return (
    <main>
      <FontAwesomeIcon
        icon={faArrowCircleUp}
        className="scrollTop"
        onClick={scrollTop}
        style={{ height: 40, display: showScroll ? "flex" : "none" }}
      />

      <section className="caravana" id="caravana">
        <h2>LIDERANÇA</h2>
        <article>
          <p>
            A caravana Israel Profético, Ontem e Hoje foi sonhada e preparada
            espiritualmente durante e após várias viagens a Terra Santa.
            Gladiston Amorim (Dinho), pastor Sênior do Ministério Atos de
            Justiça, igreja evangélica localizada em Campo Grande | MS, é um
            apaixonado por Israel e por toda a cultura deixada por Deus através
            do povo judeu. Em 2021 a Caravana, entitulada Israel Profético, visa
            compreender e trazer aprofundamento na palavra, no que tange o
            profético e sua relação com a terra que mana leite e mel.
          </p>
        </article>

        <figure>
          <img src={dinho} alt="" />
          <figcaption>Prs. Dinho & Ana Paula</figcaption>
        </figure>
      </section>

      <section className="lideranca" id="lideranca">
        <h2>A CARAVANA</h2>
        <article>
          <p>
            Israel é um relógio profético que tem balizado os tempos e as
            estações, compreender isso é ir além do óbvio e buscar
            aprofundamento nas escrituras.
          </p>
          <p>
            A caravana Israel Profético tem como objetivo visitar os principais
            pontos históricos e proféticos, trazendo uma nova revelação sobre os
            dias atuais e buscando entender profecias deixadas para o nosso
            tempo.
          </p>
          <p>
            Certamente muitas respostas podem ser encontradas por meio de sinais
            proféticos e locais específicos, o convite para embarcar nesta
            viagem vai muito além do cunho turístico ou da bagagem cultural.
          </p>
          <p>
            Para garantir uma experiência bíblica única na caravana Israel
            Profético 2021, o guia que conduzirá o roteiro é o famoso Judeu
            messiânico Salo Kapusta, que já conduziu centenas de grupos e
            participou também de documentários sobre a Terra Santa.
          </p>
        </article>

        <figure>
          <img src={fotosalo} alt="Foto Salo Kapusta" />
          <figcaption>Guia Salo Kapusta</figcaption>
        </figure>
      </section>

      <section className="photos" id="photos">
        <h2>ALGUMAS FOTOS</h2>
        <p>
          Antes de embarcar, sonhe um pouco conosco com essa viagem espiritual,
          profética e histórica
        </p>

        <Slider {...settings}>
          <div className="photo_item">
            <img src={img1} alt="" />
          </div>
          <div className="photo_item">
            <img src={img2} alt="" />
          </div>
          <div className="photo_item">
            <img src={img3} alt="" />
          </div>
          <div className="photo_item">
            <img src={img4} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img5} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img6} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img7} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img8} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img9} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img10} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img11} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img12} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img13} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img14} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img15} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img16} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img17} alt="" loading="lazy" />
          </div>
          <div className="photo_item">
            <img src={img18} alt="" loading="lazy" />
          </div>
        </Slider>
      </section>

      <section className="video">
        <h2>
          ASSISTA O NOSSO CONVITE ESPECIAL PARA CONHECER A TERRA SANTA E TER
          OUTRO ENDENDIMENTO DAS ESCRITURAS.
        </h2>
        <iframe
          width="560"
          height="315"
          allowFullScreen={true}
          src="https://www.youtube.com/embed/IDxRVqru304"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder={0}
        ></iframe>
      </section>

      <section className="investiment" id="investiment">
        <h2>INVESTIMENTO</h2>
        <p>Preço do pacote por pessoa em apartamento duplo</p>
        <PricingTable />
      </section>

      <section className="roteiro" id="roteiro">
        <h2>ROTEIRO</h2>
        <p>Confira a previsão de roteiro para a caravana.</p>
        <div className="pricing-table">
          <div className="pricing-details">
            <ul>
              <li>
                <strong>Dia 1 - Embarque em Guarulhos</strong>
              </li>
              <li>
                <strong>Dia 2 - Conexão para Tel-Aviv</strong>
              </li>
              <li>
                <strong>Dia 3 - Tel-Aviv</strong>
              </li>
              <li>
                <strong>Dia 4 - Tel-Aviv / Monte Carmelo / Galiléia</strong>
              </li>
              <li>
                <strong>Dia 5 - Galiléia</strong>
              </li>
              <li>
                <strong>Dia 6 - Galiléia</strong>
              </li>
              <li>
                <strong>Dia 7 - Galiléia / Mar Morto</strong>
              </li>
              <li>
                <strong>Dia 8 - Mar Morto / Massada / Jerusalém</strong>
              </li>
              <li>
                <strong>Dia 9 - Jerusalém</strong>
              </li>
              <li>
                <strong>Dia 10 - Jerusalém</strong>
              </li>
              <li>
                <strong>Dia 11 - Jerusalém</strong>
              </li>
              <li>
                <strong>Dia 12 - Jerusalém</strong>
              </li>
              <li>
                <strong>Dia 13 Tel-Aviv / Europa (Conexão)</strong>
              </li>
              <li>
                <strong>Dia 14 - Brasil</strong>
              </li>
            </ul>
          </div>
          <span className="warning">
            Importante: O líder e organizador da caravana, poderá ainda fazer
            pequenos ajustes no roteiro, visando obter o máximo de
            aproveitamento do tour.
          </span>
        </div>
      </section>

      <section className="register">
        <h2>
          Quer ir para a terra santa conosco e viver essas experiências
          pessoalmente? <br />
          Faça sua reserva agora mesmo no botão abaixo:
        </h2>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSd3R7LUP7FzkqYyfFtS_16HSAg3DFqqmjtyaJNnttRDhfAQfg/viewform"
          target="__blank"
        >
          Fazer sua inscrição agora
        </a>
      </section>

      <section className="contact" id="contact">
        <h2>CONTATO</h2>
        <img src={exceller} alt="" />
        <p>SDS, Bloco O, 39, Sala 318 - Edifício Venâncio VI,</p>
        <p>Asa Sul Brasília/Distrito Federal - CEP:70393-900</p>
        <p>Tel.:(61) 3967-3737</p>
        <p>WhatsApp: (61) 98172-5966 - Jason Paim</p>
        <a href="https://api.whatsapp.com/send?number=556181725966&text=Olá, gostaria de mais informações sobre a caravana Israel Profético.">
          Mandar whatsapp agora!
        </a>
      </section>
    </main>
  );
};

export default Main;
